import dynamic from 'next/dynamic';

import PartnerHead from '../src/modules/PartnerHead';

const ErrorPage = dynamic(() => import('../src/modules/404'));

const NotFoundPage = () => (
  <>
    <PartnerHead />
    <ErrorPage />
  </>
);

export default NotFoundPage;
